
<template>
<form autocomplete="off" class="">
  <is-loading v-if="isLoading" :box="true" />
  <div class="row gx-lg-4 g-3">
    <div class="col-sm-6">
      <label for="inputWeight">
       Patient Weight (kg)
      </label>
      <input type="number" v-model="data.weight"
        id="inputWeight" name="weight" class="form-control"
        placeholder="Enter Weight in kg" />
    </div> 
    <div class="col-sm-6">
      <label for="inputHeight">
       Patient Height (meters)
      </label>
      <input type="number" v-model="data.height"
        id="inputHeight" name="height" class="form-control"
        placeholder="Enter Height in meters" />
    </div> 
    <div class="col-sm-6">
      <label for="inputBP">
       Blood Pressure (mmHg)<span class="text-danger small">*</span>
      </label>
      <input type="text" v-model="data.blood_pressure"
        :class="{ 'is-invalid': v$.data.blood_pressure.$error }"
        id="inputBP" name="blood_pressure" class="form-control"
        placeholder="Enter Blood Pressure" />
      <div v-for="(item, index) in v$.data.blood_pressure.$errors" :key="index"
        class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div> 
    <div class="col-sm-6">
      <label for="inputPulse">
       Pulse (beats per minute)<span class="text-danger small">*</span>
      </label>
      <input type="number" v-model="data.pulse"
        :class="{ 'is-invalid': v$.data.pulse.$error }"
        id="inputPulse" name="pulse" class="form-control"
        placeholder="Enter Pulse in bpm" />
      <div v-for="(item, index) in v$.data.pulse.$errors" :key="index"
        class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div> 
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center">
        <label>Select Ailments  </label>
        <create-new-helper-form formType="ailment-form"/>
      </div>
      <multiselect 
        mode="tags"
        searchable
        :close-on-select="false"
        valueProp="id"
        trackBy="title" label="title"
        :options="ailmentOptions" 
        v-model="data.ailments" placeholder="-- select --">
        <template v-slot:option="{ option }">
          <div class="">
            <h6 class="mb-0">{{ option.title }} </h6>
            <span class="small">{{ option.description }}</span>
          </div>
        </template>
      </multiselect>
    </div> 
    <div class="col-12">
      <label class="form-label" for="description">Description</label>
      <div class="ckeditor-classic">
        <ckeditor v-model="data.description" :editor="editor" :config="config"></ckeditor>
      </div>
    </div>
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-12">
      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" 
        @click.prevent="editMode? update() : create()"
            type="button">{{editMode? 'Update':'Create'}}</b-button>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue';
import CreateNewHelperForm from '@/components/forms/CreateNewHelperForm.vue';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components:{
    Multiselect,
    IsLoading,
    ckeditor: CKEditor.component,
    CreateNewHelperForm
  },
  props:{
    patientId:{
      type: Number,
      default: null 
    },
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      editor: ClassicEditor,
        config:{
          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
            ]
          }
      },
      isLoading: true,
      data: {
        patient_id: this.patientId,
        weight: "",
        height: "",
        blood_pressure: "",
        pulse: "",
        description: "",
        ailments: []
      },
      mirrorData: null,
      ailmentOptions:[]
    }
  },
  validations() {
    return {
      data:{
        blood_pressure: {
          required: helpers.withMessage("Blood pressure is required", required),
        },
        pulse: {
          required: helpers.withMessage("Pulse is required", required),
        },
        description: {
          required: helpers.withMessage("Medical history is required", required),
        },
      }
    }
  },
  methods: {
    create() {
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/medical-histories/create', formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("medicalHistory/ADD_ITEM", response.data.data)
            this.$emit('addItem', response.data.data)
            this.resetForm();
          }
        })
      })
    },
    update(){
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/medical-histories/${this.editItem.id}/update`,formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("medicalHistory/UPDATE_ITEM", response.data.data)
              this.$emit('updateItem', response.data.data)
              this.resetForm()
            }
          })
      })
    },
    getOptionData(){
      this.isLoading = true;
      this.$http.get(`/ailments/all`)
        .then((response) => {
        this.isLoading = false;
        if(response.data.success){
          this.ailmentOptions = response.data.data
        }
      })
    },
    resetForm(){
      this.data = {...this.mirrorData}
      this.v$.$reset()
      this.$emit('closeMe')
    },
  },
  created(){
    this.getOptionData()
  },
  mounted(){
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
      this.data.ailments = this.data.ailments.map(i => i.id);
    }
  }
}
</script>

<style>

</style>