<template>
<div>
   <button @click.prevent="popupModalShow = !popupModalShow" 
   class="text-primary p-0 text-decoration-none btn btn-link"
    type="button" style="font-size:12px;">CREAT NEW 
    <i class="align-bottom ri-add-circle-fill"></i></button>

   <b-modal v-model="popupModalShow" hide-footer centered
    :title="formElement.title" :size="modelSize"
    title-class="fs-6" @hide="initializeAll()">

     <component :is="formElement.type"
     v-if="popupModalShow" @closeMe="initializeAll()"
     @addItem="addItem($event)" ></component>

  </b-modal>
</div>
</template>

<script>

import AilmentForm from '@/components/forms/AilmentForm.vue';

export default {
    components:{
        AilmentForm,
    },
    props:{
        modelSize:{
            type: String,
            default:'md'
        },
        formType:{
            type: String,
            default: ''
        }
    },
    data() {
        return{
            popupModalShow: false,
            formTypes:[
                {
                    type: 'ailment-form',
                    title: 'Create new ailment',
                    store: 'ailment/fetchAllAilments'
                },
            ]
        }
    },
    computed:{
        formElement(){
            return this.formTypes.find(i => i.type == this.formType)
        }
    },
    methods:{
        addItem(item){
            this.$store.dispatch(this.formElement.store, true)
        },
        initializeAll(){
            this.popupModalShow = false;
        }
    },
}
</script>

<style>

</style>